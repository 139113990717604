import { Link, useNavigate } from 'react-router-dom'
import LigarDesligar from './components_layout/LigarDesligar'

const Aside = ()=>{
    const navigate = useNavigate();

    const openAside = ()=>{
        let element = document.getElementById("aside");
        element.style.left='0px'
    }

    const closeAside = ()=>{
        let element = document.getElementById("aside");
        element.style.left='-300px'
    }
    

    const goToRoute = (par)=>{
        navigate(par);
        closeAside()
    }

    return(
        <aside>
            <nav id="aside">
                <div className='barra_esq_nav'>
                    <div className='icones_superiores'>
                        <i className="bi bi-x-lg" onClick={closeAside}></i>
                        <Link to='/'><i className="bi bi-speedometer"></i></Link>
                        <i className="bi bi-gear"></i>
                    </div>
                    <div className='icones_inferiores'>
                        <LigarDesligar />
                    </div>
                </div>
                <div className='barra_dir_nav'>
                    <ul>
                        <li onClick={()=>goToRoute('/')}><i className="bi bi-speedometer"></i>Controle</li>
                        <li onClick={()=>goToRoute('/cadastrar')}><i className="bi bi-file-earmark-plus"></i>Cadastrar Produtos</li>
                        <li onClick={()=>goToRoute('/clientes')}><i className="bi bi-people"></i>Usuários</li>
                        <li onClick={()=>goToRoute('/pedidos_entregues')}><i className="bi bi-card-checklist"></i>Pedidos Entregues</li>
                        <li onClick={()=>goToRoute('/pedidos_recusados')}><i className="bi bi-x-square"></i>Pedidos Cancelados</li>
                        <li onClick={()=>goToRoute('/controle-comercial')}><i className="bi bi-graph-up-arrow"></i>Controle Comercial</li>
                        <li onClick={()=>goToRoute('/estoque')}><i className="bi bi-boxes"></i>Estoque</li>
                    </ul>
                </div>
            </nav>
            <div className="btn_aside" onClick={openAside}><i className="bi bi-list"></i></div>
        </aside>
        )
}
export default Aside