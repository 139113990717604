import './App.css';
import PandaRouter from './routes/PandaRouter';


function App() {
  return (
    <div className="App">
      <PandaRouter />
    </div>
  );
}

export default App;
