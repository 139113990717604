import firebase from "firebase";
import TitleComum from './componentesCadastro/TitleComum'
import ListaClientes from './componentesCadastro/ListaClientes';

const Clientes = ()=>{

    const db = firebase.firestore()

    return(
        <div>
			<div className="container_comum">
            <TitleComum name='Usuários Cadastrados' icon='bi bi-people' />
                <div className="content_comum">
                    <div className="row_comum">
                    <ListaClientes db={db} />
                    </div>
                </div>
			</div>
        </div>
        
        )
}

export default Clientes