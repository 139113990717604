import {useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {storage} from './../../firebase'
import firebase from 'firebase';

const CadastroPizzaDoce = ({db})=>{

    const [file, setFile] = useState(null);
    useEffect(()=> {
    }, [])

    const cadastrarPizza = (e)=>{
		e.preventDefault();
        let saborPizza = document.getElementById('saborPizza').value;
        let ingredientesPizza = document.getElementById('ingredientesPizza').value;
        let valorPizzaGrande = parseFloat(document.getElementById('valorPizzaGrande').value);
        let valorPizzaBroto = parseFloat(document.getElementById('valorPizzaBroto').value);
        var storageRef = firebase.storage().ref();
        let nameRenamed = uuidv4()+file.name;

        if(file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg'){
            if(file.size < 123000){
                const uploadTask = storageRef.child('images_pizza/' + nameRenamed).put(file);
                uploadTask.on("state_changed",function(snapshot){
                    //document.getElementById('btn_cadastrar').disabled = true;
                  }, function(error){
                      alert(error.message);
                  }, function(){
                      storage.ref("images_pizza").child(nameRenamed).getDownloadURL()
                      .then(function(url){
                       db.collection('pizzasdoces').add({
                          saborPizza: saborPizza,
                          ingredientesPizza: ingredientesPizza,
                          valorPizzaBroto: valorPizzaBroto,
                          valorPizzaGrande:valorPizzaGrande,
                          disponibilidade: true,
                          image: url,
                          timestamp: firebase.firestore.FieldValue.serverTimestamp()
                        })
                        setFile(null);
            
                        document.getElementById('saborPizza').value = '';
                        document.getElementById('ingredientesPizza').value = '';
                        document.getElementById('valorPizzaGrande').value = '';
                        document.getElementById('valorPizzaBroto').value = '';
                        document.getElementById('file').value = '';
                        document.getElementById('btn_cadastrar').disabled = false;
                      })
                  })
                }else{
                    alert('A imagem excede o tamanho permitido de 100kb')
                }
        }else{
            alert('Formato de arquivo não aceito pelo aplicativo.')
        }
        
	}

    return(
        <div className="card_cadastro">
            <div>
                 <h2>Cadastrar Pizza Doce</h2>
                 <form onSubmit={(e)=>cadastrarPizza(e)}>
                    <input type="text" name="sabor" placeholder="Sabor..." id='saborPizza' />
                    <textarea placeholder="Ingredientes..." id='ingredientesPizza' ></textarea>
                    <input type="number" min="0" max="1000" step=".01"  placeholder="Valor da Grande..." id='valorPizzaGrande' />
                    <input type="number" min="0" max="1000" step=".01"  placeholder="Valor da Broto..." id='valorPizzaBroto' />
                    <label htmlFor="file">Escolher arquivo</label>
                    <input type="file"  id="file" name="file" onChange={(e)=>setFile(e.target.files[0])}  />
                    <div className='content_btn'><button className="btn01" type="submit">Cadastrar</button></div>      
                </form>
            </div>
        </div>
        )
}

export default CadastroPizzaDoce