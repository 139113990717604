const Footer = ({deslogar})=>{

	
	const whatsappLink = 'https://api.whatsapp.com/send?phone=5511985829909&text=Ol%C3%A1%2C%20tenho%20uma%20d%C3%BAvida%20sobre%20um%20de%20seus%20produtos.'; 

    return(
    <footer>
		<div className='icon_footer'><a href='/'><i className="bi bi-house"></i><span>Home</span></a></div>
		<div className='icon_footer'><a target="_blank" href={whatsappLink}><i className="bi bi-whatsapp"></i><span>Suporte</span></a></div>
		<div className='icon_footer' onClick={deslogar}><i className="bi bi-arrow-right-square"></i><span>Logout</span></div>
	</footer>
    )
}
export default Footer