import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBkdGRORA3HYk6G219VA1Qy6mD4rWQhUjk",
  authDomain: "pizzaplanet-f9da6.firebaseapp.com",
  projectId: "pizzaplanet-f9da6",
  storageBucket: "pizzaplanet-f9da6.appspot.com",
  messagingSenderId: "1014087092660",
  appId: "1:1014087092660:web:687546ef6f9a2280c7cae1"
});
  
  const db = firebase.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();
  const functions = firebase.functions();
  const provider = new firebase.auth.GoogleAuthProvider();
  
  export {db, auth, storage, functions, provider};