import TitleComum from './componentesCadastro/TitleComum'
import firebase from "firebase";
import { useState, useEffect } from 'react';
import moment from 'moment'
import 'moment/locale/pt-br'
import {Link} from 'react-router-dom'

const Despesas = ()=>{
    const db = firebase.firestore()
    const [date, setDate] = useState(new Date())
    const [despesas, setDespesas] = useState([])
    
    var mes = moment(date).locale('pt').format('MM')
	var ano = moment(date).format('YYYY')

    const [month, setMonth] = useState(mes)
    const [year, setYear] = useState(ano)

    var mesA = mes
    var anoA = ano
    var mesB = mes - 1 < 1 ? mes + 11 : mes -1
    var anoB = mes - 1 < 1 ? ano - 1 : ano
    var mesC = mes - 2 < 1 ? mes + 10 : mes - 2
    var anoC = mes - 2 < 1 ? ano -1 : ano
    var mesD = mes - 3 < 1 ? mes + 9 : mes - 3
    var anoD = mes - 3 < 1 ? ano - 1 : ano
    var mesE = mes - 4 < 1 ? mes + 8 : mes - 4
    var anoE = mes - 4 < 1 ? ano - 1 : ano
    var mesF = mes - 5 < 1 ? mes + 7 : mes - 5
    var anoF = mes - 5 < 1 ? ano - 1 : ano

    const determinarData =(e)=>{
        e.preventDefault()
        let monthSelected = document.getElementById('mes').value
        let yearSelected = monthSelected == mesA ? anoA:monthSelected == mesB ? anoB : monthSelected == mesC ? anoC : monthSelected == mesD ? anoD : monthSelected == mesE ? anoE : anoF

        setMonth(monthSelected)
        setYear(yearSelected)
    }

    const cadastrarDespesa = (e)=>{
        e.preventDefault()
        let item = document.getElementById('despesa').value;
        let valor = parseFloat(document.getElementById('valor').value)

        if(item !== '' & valor !== '')
        {document.getElementById('btn_submit').disabled = true
            db.collection('despesas').add({
                item: item,
                mes: parseInt(month),
                ano: parseInt(year),
                valor: valor,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            }).then(()=>{
                document.getElementById('despesa').value = ''
                document.getElementById('valor').value = ''
                document.getElementById('btn_submit').disabled = false
            })
        }else{
            console.log('Nenhuma despesa preenchida')
        }
        
    }
    useEffect(()=>{
        db.collection('despesas').orderBy('timestamp', 'desc').onSnapshot(function(snapshot){
            setDespesas(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

    const despesasMesA = despesas.filter(despesas => despesas.data.mes == mesA && despesas.data.ano == anoA)
    const despesasMesB = despesas.filter(despesas => despesas.data.mes == mesB && despesas.data.ano == anoB)
    const despesasMesC = despesas.filter(despesas => despesas.data.mes == mesC && despesas.data.ano == anoC)
    const despesasMesD = despesas.filter(despesas => despesas.data.mes == mesD && despesas.data.ano == anoD)
    const despesasMesE = despesas.filter(despesas => despesas.data.mes == mesE && despesas.data.ano == anoE)
    const despesasMesF = despesas.filter(despesas => despesas.data.mes == mesF && despesas.data.ano == anoF)

    const deleteDespesa = (e, par)=>{
        e.preventDefault()
        db.collection('despesas').doc(par).delete()
    }

    return(
        <div className="container_comum">
            <TitleComum name='Lançar Despesas' icon='bi bi-cash-coin' />
            <div className="content_comum">
                <div className="row_comum">
                    <div className='lanca_despesa'>
                        <form onSubmit={cadastrarDespesa}>
                            <select name="mes" id="mes" onChange={determinarData}>
                                <option value={mesA}>{mesA} / {anoA}</option>
                                <option value={mesB}>{mesB} / {anoB}</option>
                                <option value={mesC}>{mesC} / {anoC}</option>
                                <option value={mesD}>{mesD} / {anoD}</option>
                                <option value={mesE}>{mesE} / {anoE}</option>
                                <option value={mesF}>{mesF} / {anoF}</option>
                            </select>
                            <input name='despesa' id='despesa' type='text' placeholder="Despesa..." />
                            <input name='valor' id='valor' type='number' min="0" max="1000" step=".01" placeholder="valor..." />
                            <div className='despesa_btn'><button className="btnDespesa" type="submit" id='btn_submit'><i className="bi bi-plus-lg"></i></button></div>
                        </form>
                        <Link to="/controle-comercial"><button><i className="bi bi-arrow-return-left"></i> Voltar</button></Link>
                    </div>
                    <div className='lista_despesas'>
                        <ul><div className='title_desp'>Despesas mês {mesA}/{anoA}</div>
                            {despesasMesA.map((val)=>{
                                return(
                                    <li key={val.id}><div style={{width:'60%'}}>{val.data.item} - </div>R${(val.data.valor).toFixed(2)} <i className="bi bi-trash3" onClick={(e)=>deleteDespesa(e, val.id)}></i></li>
                                )
                            })}
                        </ul>
                        <ul><div className='title_desp'>Despesas mês {mesB}/{anoB}</div>
                            {despesasMesB.map((val)=>{
                                return(
                                    <li key={val.id}><div style={{width:'60%'}}>{val.data.item} - </div>R${(val.data.valor).toFixed(2)} <i className="bi bi-trash3" onClick={(e)=>deleteDespesa(e, val.id)}></i></li>
                                )
                            })}
                        </ul>
                        <ul><div className='title_desp'>Despesas mês {mesC}/{anoC}</div>
                            {despesasMesC.map((val)=>{
                                return(
                                    <li key={val.id}><div style={{width:'60%'}}>{val.data.item} - </div>R${(val.data.valor).toFixed(2)} <i className="bi bi-trash3" onClick={(e)=>deleteDespesa(e, val.id)}></i></li>
                                )
                            })}
                        </ul>
                        <ul><div className='title_desp'>Despesas mês {mesD}/{anoD}</div>
                            {despesasMesD.map((val)=>{
                                return(
                                    <li key={val.id}><div style={{width:'60%'}}>{val.data.item} - </div>R${(val.data.valor).toFixed(2)} <i className="bi bi-trash3" onClick={(e)=>deleteDespesa(e, val.id)}></i></li>
                                )
                            })}
                        </ul>
                        <ul><div className='title_desp'>Despesas mês {mesE}/{anoE}</div>
                            {despesasMesE.map((val)=>{
                                return(
                                    <li key={val.id}><div style={{width:'60%'}}>{val.data.item} - </div>R${(val.data.valor).toFixed(2)} <i className="bi bi-trash3" onClick={(e)=>deleteDespesa(e, val.id)}></i></li>
                                )
                            })}
                        </ul>
                        <ul><div className='title_desp'>Despesas mês {mesF}/{anoF}</div>
                            {despesasMesF.map((val)=>{
                                return(
                                    <li key={val.id}><div style={{width:'60%'}}>{val.data.item} - </div>R${(val.data.valor).toFixed(2)} <i className="bi bi-trash3" onClick={(e)=>deleteDespesa(e, val.id)}></i></li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        )
}
export default Despesas