import firebase from "firebase";
import CadastroPizza from './componentesCadastro/CadastroPizza'
import CadastroPizzaDoce from "./componentesCadastro/CadastroPizzaDoce";
import CadastroBebida from './componentesCadastro/CadastroBebida'
import CadastroPromo from './componentesCadastro/CadastroPromo'
import ListaPizza from './componentesCadastro/ListaPizza'
import ListaPizzaDoce from './componentesCadastro/ListaPizzaDoce'
import ListaBebidas from './componentesCadastro/ListaBebidas'
import TitleComum from './componentesCadastro/TitleComum'
import ListaPromo from "./componentesCadastro/ListaPromo";

const Cadastrar = ()=>{
    const db = firebase.firestore()

    return( 
        <div className="container_comum">
            <TitleComum name='Cadastro de Produtos' icon='bi bi-speedometer' />
            <div className="content_comum">
                <div className="row_comum">
                    <div className="col_comum">
                    <CadastroPizza db={db} />
                    <ListaPizza db={db} />
                    <CadastroPizzaDoce db={db} />
                    <ListaPizzaDoce db={db} />
                    <CadastroBebida db={db} />
                    <ListaBebidas db={db} />
                    <CadastroPromo db={db} />
                    <ListaPromo db={db} />
                    </div>
                </div>
            </div> 
        </div>  
        )
}

export default Cadastrar