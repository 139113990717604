import ControlStatus from "./ControlStatus"
import HomeProdutos from './HomeProdutos'
import HomeModalRecusa from './HomeModalRecusa'

const Card =({data, action01, idPedido, nao, sim, action02})=>{

    const callWhats = (par)=>{
        const whatsNum = par.replace(/[^0-9]/g,'')
        let url = 'https://api.whatsapp.com/send?phone=55'+whatsNum+'&text=Boa%20Noite.'
        var win = window.open(url, '_blank');
        win.focus();
    } 

    return(
        <div className="card">
            <div className='encerra_pedido' id={'recusa'+idPedido}>
				<HomeModalRecusa sim={sim} nao={nao} idPedido={idPedido} nPedido={data.numeroPedido}/>
			</div>
            <div>
                <h2>N° {data.numeroPedido}</h2>
                <HomeProdutos produtos={data.produtos} quantidades={data.quantidades} />
            </div>
            <div>
                <hr/>        
                <h3>Total: R${(data.total).toFixed(2)}</h3>
                <p>{data.endereço}</p>
                <p>{data.cliente}</p>
                <hr/>
                        
                <h3>{(data.formaEntrega).charAt(0).toUpperCase()+(data.formaEntrega).substr(1)}</h3>
                <p>Meio de pagamento: {(data.formaPagamento).charAt(0).toUpperCase()+(data.formaPagamento).substr(1)}<br/>
                Troco para: {data.trocoPara}</p>
                {data.statusPedido === 'pedido cancelado'
                ?(<>
                    <h4 style={{color:'darkred'}}>Esse pedido foi cancelado</h4>
                    <p>Motivo: {data.motivo}</p>
                </>)
                :data.statusPedido === 'entregue'
                ?(<>
                    <h4 style={{color:'darkgreen'}}>Esse pedido foi entregue</h4>
                    <p>Hora da entrega: {data.horaEntrega}</p>
                </>)
                :(<ControlStatus action01={action01} idPedido={idPedido} statusPedido={data.statusPedido}/>)}
                            
                <div className="control_buttons">
                    <button onClick={()=>action02(idPedido,data.numeroPedido)}><i className="bi bi-x-circle"></i> Cancelar</button>
                    <button onClick={()=>callWhats(data.cliente)}><i className="bi bi-whatsapp"></i> Cliente</button>
                </div>
            </div>
        </div>
        )
}
export default Card