const CadastroBebida = ({db})=>{

    const cadastrarBebida = (e)=>{
		e.preventDefault();
        let nomeBebida = document.getElementById('nomeBebida').value;
        let tipoBebida = document.getElementById('tipoBebida').value;
        let valorBebida = parseFloat(document.getElementById('valorBebida').value);

	    db.collection('bebidas').add({
            nomeBebida: nomeBebida,
            tipoBebida: tipoBebida,
            valor:valorBebida,
            disponibilidade: true,
          })

        document.getElementById('nomeBebida').value = '';
        document.getElementById('tipoBebida').value = '';
        document.getElementById('valorBebida').value = '';
	}

    return(
        <div className="card_cadastro">
            <div>
                <h2>Cadastrar Bebidas</h2>
                <form onSubmit={(e)=>cadastrarBebida(e)}>
                    <input type="text" id="nomeBebida" placeholder="Nome da bebida (especificar ML)..." />
                    <input id="valorBebida" type="number" min="0" max="100" step=".01" placeholder="Valor da Bebida R$..." style={{marginRight:'4.2px'}}/>
                    <div className="selecttipo">
                        <select name="drinkList" form="drinkList" id="tipoBebida" required>
                            <option value='' defaultChecked disabled>Tipo...</option>
                            <option value="cerveja">Cerveja</option>
                            <option value="refrigerante">Refrigerante</option>
                            <option value="outro">Outro</option>
                        </select>
                    </div>
                    <div className='content_btn'><button className="btn01" type="submit">Cadastrar</button></div>
                </form>
            </div>
        </div>
        )
}

export default CadastroBebida