import { useState, useEffect } from 'react';

const ListaBebidas = ({db})=>{
    const [bebidas, setBebidas] = useState([]);

    useEffect(()=>{

        db.collection('bebidas').orderBy('nomeBebida','asc').onSnapshot(function(snapshot){
            setBebidas(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

    const setDisponivel = (e , par)=>{
        e.preventDefault();
        db.collection('bebidas').doc(par).update({
            disponibilidade: false,
        })
    }
    const setIndisponivel = (e , par)=>{
        e.preventDefault();
        db.collection('bebidas').doc(par).update({
            disponibilidade: true,
        })
    }
          
    return(
        <div className="card_cadastro" style={{background:'#b8d5ff'}} >
        <div style={{display:'block'}}>
            <h2>Bebidas Cadastradas</h2>
            <div className='overflow mxh250'>
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Tipo</th>
                            <th>Preço</th>
                            <th>Disp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bebidas.map((val)=>{
                            return(
                                <tr key={val.id}>
                                    <td>{val.data.nomeBebida}</td>
                                    <td>{val.data.tipoBebida}</td>
                                    <td>{(val.data.valor).toFixed(2)}</td>
                                    {val.data.disponibilidade === true ?
                                (<td onClick={(e)=>setDisponivel(e ,val.id)} className='disponibilidade' style={{color:'#21bc32'}}><i className="bi bi-check-circle-fill"></i></td>)
                                :(<td onClick={(e)=>setIndisponivel(e ,val.id)} className='disponibilidade'><i className="bi bi-x-octagon-fill" style={{color:'#bf2120'}}></i></td>)}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </div>
                </div>
            </div>
    )
}

export default ListaBebidas