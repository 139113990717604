import Sent from './../../assets/img/sent.png'
import Received from './../../assets/img/received.png'
import Cooking from './../../assets/img/coocking.png'
import Delivery from './../../assets/img/delivery.png'
import Done from './../../assets/img/done.png'

const ControlStatus =({action01, idPedido, statusPedido})=>{

    //console.log(statusPedido)
    return(
        <div className="control_status">
            <img alt="" src={Sent} style={{cursor:'default', opacity:'0.4'}} />
            {statusPedido === 'recebido'||
             statusPedido === 'preparando'||
             statusPedido === 'saiu para entrega'||
             statusPedido === 'entregue'
            ?(<img alt="" src={Received} style={{cursor:'default', opacity:'0.4'}} />)
            :(<img alt="" src={Received} onClick={(e)=>action01(e, idPedido,'recebido')} />)}
            {statusPedido === 'preparando'||
             statusPedido === 'saiu para entrega'||
             statusPedido === 'entregue'
            ?(<img alt="" src={Cooking} style={{cursor:'default', opacity:'0.4'}} />)
            :(<img alt="" src={Cooking} onClick={(e)=>action01(e, idPedido,'preparando')} />)}
            {statusPedido === 'saiu para entrega'||
             statusPedido === 'entregue'
            ?(<img alt="" src={Delivery} style={{cursor:'default', opacity:'0.4'}} />)
            :(<img alt="" src={Delivery} onClick={(e)=>action01(e, idPedido,'saiu para entrega')} />)}
            <img alt="" src={Done} onClick={(e)=>action01(e, idPedido,'entregue')} />
        </div>
        )
}
export default ControlStatus