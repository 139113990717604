const HomeProdutos = ({produtos, quantidades})=>{

    return(
            <ul>
            {quantidades.map((val, index)=>{
                return (
                    <li key={index}>{val} - {produtos[index]}</li>
                )
                
            })}
            </ul>
    )
}

export default HomeProdutos