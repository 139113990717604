import LogoVertical from './../assets/img/logo_vertical_dark.png'
import LogoGoogle from './../assets/img/google_logo.png'

const Logon = ({logar, loginGoogle})=>{

    return(
        
        <div className='body_container'>
                <img className="logon_img" alt='' src={LogoVertical} />
                <div className="title_logon">
                <i className="bi bi-robot"></i>
                <h4>Painel Admin</h4>
                </div>
                <div className='form_logon'>
                    <form onSubmit={(e)=>logar(e)}>
                    <input
                        id="email-login"
                        name="email"
                        placeholder="E-mail..."
                        type="email"
                        style={{width:'100%'}}
                    />
                    <input
                        id="senha-login"
                        name="password"
                        placeholder="Senha..."
                        type="password"
                    />
                    <input type="submit" name="action" value="Entrar"/>
                    </form>                    
                    <div className="login_google">
                        <button onClick={loginGoogle}><img alt="" src={LogoGoogle} /><span>Entrar com Google</span></button>
                    </div>
                </div>
        </div>
    )
}

export default Logon