import { useState, useEffect } from 'react';

const ListaPizzaDoce = ({db})=>{
    const [pizzas, setPizzas] = useState([]);

    useEffect(()=>{

        db.collection('pizzasdoces').orderBy('saborPizza').onSnapshot(function(snapshot){
            setPizzas(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

    const setDisponivel = (e , par)=>{
        e.preventDefault();
        db.collection('pizzasdoces').doc(par).update({
            disponibilidade: false,
        })
    }
    const setIndisponivel = (e , par)=>{
        e.preventDefault();
        db.collection('pizzasdoces').doc(par).update({
            disponibilidade: true,
        })
    }

    const edit = (e , par)=>{
        e.preventDefault();
        alert(par)
    }

    const openAside = ()=>{
        let asideButton = document.getElementById("asidebutton");
        let element = document.getElementById("aside");
        let nav = document.getElementById("nav");
        
        asideButton.classList.toggle('is-active');
        
        element.classList.toggle('aside_aberto');
		element.classList.toggle('aside_fechado');
			
		nav.classList.toggle('nav_oculto');
		nav.classList.toggle('nav_visivel');
    }
          
    return(
        <div className="card_cadastro" style={{background:'#b8d5ff'}} >
        <div style={{display:'block'}}>
            <h2>Pizzas Doces Cadastradas</h2>
            <div className='overflow mxh250'>
            <table>
                <thead>
                    <tr>
                        <th>Sabor</th>
                        <th>Ingredientes</th>
                        <th>Preço GD</th>
                        <th>Preço BT</th>
                        <th>Disp</th>
                    </tr>
                </thead>
                <tbody>
                    {pizzas.map((val)=>{
                        return(
                            <tr key={val.id}>
                                <td>{val.data.saborPizza}</td>
                                <td>{val.data.ingredientesPizza}</td>
                                <td>R${val.data.valorPizzaGrande.toFixed(2)}</td>
                                <td>R${val.data.valorPizzaBroto.toFixed(2)}</td>
                                {val.data.disponibilidade === true ?
                                (<td onClick={(e)=>setDisponivel(e ,val.id)} className='disponibilidade' style={{color:'#21bc32'}}><i className="bi bi-check-circle-fill"></i></td>)
                                :(<td onClick={(e)=>setIndisponivel(e ,val.id)} className='disponibilidade'><i className="bi bi-x-octagon-fill" style={{color:'#bf2120'}}></i></td>)}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>
        </div>
    </div>
    )
}

export default ListaPizzaDoce