import { useState, useEffect } from 'react';
import firebase from "firebase";
import TitleComum from './componentesCadastro/TitleComum'
import Card from './components/Card'
import * as moment from 'moment';
import 'moment/locale/pt-br';

const Entregues = ()=>{
	const db = firebase.firestore()
	const [pedido, setPedido] = useState([]);
	const [date, setDate] = useState(new Date())
	var horaEntrega = moment().format('llll');

    useEffect(()=>{
        db.collection('pedidos').orderBy('timestamp').onSnapshot(function(snapshot){
            setPedido(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])
    const pedidos = pedido.filter(pedido => pedido.data.statusPedido === 'entregue')
    //console.log(pedidos)
    const updateStatus = (e, par1 , par2)=>{
		e.preventDefault();
		db.collection('pedidos').doc(par1).update({
            statusPedido: par2,
			horaEntrega: horaEntrega,
          })
	}
    const recusaPedido = (par1,par2)=>{
		alert('Esse pedido foi entregue e não é possível sofrer nenhuma ação!')
        //document.getElementById('recusa'+par1).style.display = 'block';
    }
	const nao = (par1)=>{
        //document.getElementById(par1).style.display = 'none';
		document.getElementById('recusa'+par1).style.display = 'none';
    }
    const sim = (e,par1)=>{
        e.preventDefault()
        let motivo = document.getElementById('motivo').value;

		if(motivo.length > 3)
        {db.collection('pedidos').doc(par1).update({
            statusPedido: 'pedido cancelado',
            motivo: motivo,
            //timestamp: db.FieldValue.serverTimestamp(),
          })
        document.getElementById('recusa'+par1).style.display = 'none';}
        else{alert('Você deve especificar um motivo.')}/**/
    }

    return (
        <div className="container_comum">
            <TitleComum name='Pedidos Entregues' icon='bi bi-card-checklist' />
            <div className="content_comum">
                <div className="row_comum">
                    <div className="col_comum">
                        {pedidos.map((val)=>{
                            return <Card
                                    key={val.id}
                                    data={val.data}
                                    action01={updateStatus}
                                    idPedido={val.id}
                                    nao={nao}
                                    sim={sim}
                                    action02={recusaPedido}
                                    />
                        })}
                    </div>
                </div>
            </div> 
        </div>        
    )
}

export default Entregues