import { useEffect,useState } from 'react';
import firebase from "firebase";

const LigarDesligar = ()=>{

    const db = firebase.firestore()
    const [status, setStatus] = useState([])
    useEffect(()=>{
        db.collection('openclose').onSnapshot(function(snapshot){
            setStatus(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
            }))
        })
        },[])
    
    if(status.length > 0){
       var statusAtual = status[0].data.status;
    }else{
        var statusAtual = true
    }

    //console.log(statusAtual)

    const openClose = ()=>{
		let x = document.getElementById("toggle2");
		x.checked == true
		? db.collection('openclose').doc('vWmddj5jsyZ0HZMTxYfy').update({
            status: true,
          }).then(()=>{alert('Olá! Seja bem vindo')})
          
		: db.collection('openclose').doc('vWmddj5jsyZ0HZMTxYfy').update({
            status: false,
          }).then(()=>{alert('Boa noite, até amanhã!')})
	}

    return(
    <div className="toggle">
	  <input type="checkbox" id="toggle2" onChange={openClose} checked={statusAtual}/>
	  <label htmlFor="toggle2"></label>
	</div>
        )
}

export default LigarDesligar