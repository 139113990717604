const AccessDanied = ({deslogar})=>{

    return(
        <div>
            <div className='access_danied'>
                <h2>Acesso Negado!</h2>
                <h5>Por favor, desconecte-se e faça login com uma conta válida.</h5>
                <button className="btn01" onClick={deslogar} style={{position:'absolute'}}>deslogar</button>
            </div>      
        </div>
    )
}

export default AccessDanied