const UsuarioModal = ({db, idUsuario, nao, nomeUsuario, tipoUsuario, idDoc})=>{

    const sim = (e)=>{
        e.preventDefault()

        var retorno = tipoUsuario == "admin" ? "cliente" : "admin";

		db.collection('users').doc(idUsuario).collection('cadastro').doc(idDoc).update({
            typeOfCad: retorno,
        })
        alert('Usuário atualizado!')
        document.getElementById('muda_usuario').style.display = 'none';
    }

    return (
        <>
        
            <div className='content_muda_usuario'>
            <p>Tem certeza que deseja tornar <b>{nomeUsuario}</b> um {tipoUsuario === 'cliente' ?(<>administrador</>):(<>cliente</>)}?</p>
                <button onClick={sim}>Sim</button>
                <button onClick={nao}>Não</button>
            </div>
        </>
    )
}

export default UsuarioModal