import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );

const ComercialChart01 = ({labels, qtd, sumall, despesas})=>{

    const data = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'Pedidos no mês',
            borderColor: 'rgb(73, 191, 191)',
            backgroundColor: 'rgb(165,223,223)',
            borderWidth: 2,
            fill: false,
            data: qtd,
          },
          {
            type: 'bar',
            label: 'Lucro Bruto',
            backgroundColor: 'rgb(154, 208, 246)',
            data: sumall,
            borderColor: 'white',
            borderWidth: 2,
          },
          {
            type: 'bar',
            label: 'despesas',
            backgroundColor: 'rgb(255, 177, 193)',
            data: despesas,
          },
        ],
      };

    return(
        
		<Chart type='bar' data={data} />
		
        )
}

export default ComercialChart01