import {useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {storage} from './../../firebase'
import firebase from 'firebase';

const CadastroPromo = ({db})=>{

    const [file, setFile] = useState(null);

    useEffect(()=> {
    }, [])

    const cadastrarPromo = (e)=>{
		e.preventDefault();
        let nomePromo = document.getElementById('nomePromo').value;
        let contPromo = document.getElementById('contPromo').value;
        let valorPromo = parseFloat(document.getElementById('valorPromo').value);
        var storageRef = firebase.storage().ref();
        let nameRenamed = uuidv4()+file.name;

        const uploadTask = storageRef.child('images_promo/' + nameRenamed).put(file);

        uploadTask.on("state_changed",function(snapshot){
            document.getElementById('btn_cadastrar').disabled = true;
          }, function(error){
              alert(error.message);
          }, function(){
              storage.ref("images_promo").child(nameRenamed).getDownloadURL()
              .then(function(url){
               db.collection('promo').add({
                  nomePromo: nomePromo,
                  contPromo: contPromo,
                  valor:valorPromo,
                  disponibilidade: true,
                  image: url,
                  timestamp: firebase.firestore.FieldValue.serverTimestamp()
                })
                setFile(null);
    
                document.getElementById('nomePromo').value = '';
                document.getElementById('contPromo').value = '';
                document.getElementById('valorPromo').value = '';
                document.getElementById('file').value = '';
                document.getElementById('btn_cadastrar').disabled = false;
              })
          })

	}

    return(
        <div className="card_cadastro">
                            <div>
                                <h3>Cadastro de Promoções</h3>
                                <form onSubmit={cadastrarPromo} id="drinkList">
                                    <input type="text" id="nomePromo" placeholder="Nome da promoção..." />
                                    <input type="text" id="contPromo" placeholder="Ítens inclusos na promoção..." />
                                    <input id="valorPromo" type="number" min="0" max="100" step=".01" placeholder="Valor da promoção R$..." style={{marginRight:'4.2px', width:'calc(50% - 8px)'}}/>
                                    <label for="file">Escolher arquivo</label>
                                    <input type="file" id="file" name="file" onChange={(e)=>setFile(e.target.files[0])}  />
                                    <div className='content_btn'><button className="btn01" type="submit">Cadastrar</button></div>
                                </form>
                            </div>
                        </div>
        )
}

export default CadastroPromo