import TitleComum from './componentesCadastro/TitleComum'
import firebase from "firebase";
import { useState, useEffect } from 'react';
import ComercialChart01 from './components/ComercialChart01';
import ComercialChart02 from './components/ComercialChart02';
import moment from 'moment'
import {Link} from 'react-router-dom'


const Comercial =()=>{
    const db = firebase.firestore()
    const [date, setDate] = useState(new Date())

	var dia = moment(date).format('DD');
	var mes = moment(date).format('MM');
	var ano = moment(date).format('YYYY');
	var month = moment(date).format('MM');
	//var datefilter = dia+month+ano

    var labels = ['']
    var mesAtual = ('') 

	function mesBr() {
		if (mes === '01') {
		  mesAtual = 'Janeiro';
          labels = ['Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro', 'Janeiro']
		} else if (mes === '02') {
		  mesAtual = 'Fevereiro';
          labels = ['Setembro', 'Outubro', 'Novembro', 'Dezembro', 'Janeiro', 'Fevereiro']
		} else if (mes === '03') {
		  mesAtual = 'Março';
          labels = ['Outubro', 'Novembro', 'Dezembro', 'Janeiro', 'Fevereiro', 'Março']
		} else if (mes === '04') {
		  mesAtual = 'Abril';
          labels = ['Novembro', 'Dezembro', 'Janeiro', 'Fevereiro', 'Março', 'Abril']
		} else if (mes === '05') {
		  mesAtual = 'Maio';
          labels = ['Dezembro', 'Janeiro', 'Fevereiro', 'Março','Abril','Maio']
		} else if (mes === '06') {
		  mesAtual = 'Junho';
          labels = ['Janeiro', 'Fevereiro', 'Março','Abril','Maio', 'Junho']
		} else if (mes === '07') {
		  mesAtual = 'Julho';
          labels = ['Fevereiro', 'Março','Abril','Maio', 'Junho','Julho']
		} else if (mes === '08') {
		  mesAtual = 'Agosto';
          labels = ['Março','Abril','Maio', 'Junho','Julho','Agosto']
		} else if (mes === '09') {
		  mesAtual = 'Setembro';
          labels = ['Abril','Maio', 'Junho','Julho','Agosto','Setembro']
		} else if (mes === '10') {
		  mesAtual = 'Outubro';
          labels = ['Maio', 'Junho','Julho','Agosto','Setembro','Outubro']
		} else if (mes === '11') {
		  mesAtual = 'Novembro';
          labels = ['Junho','Julho','Agosto','Setembro','Outubro','Novembro']
		} else if (mes === '12') {
		  mesAtual = 'Dezembro';
          labels = ['Julho','Agosto','Setembro','Outubro','Novembro','Dezembro']
		}
	  }
	mesBr();
    var mesA = mes - 0;
    var mesB = mes - 1;
    var mesC = mes - 2;
    var mesD = mes - 3;
    var mesE = mes - 4;
    var mesF = mes - 5;

    mesB = mesB < 1 ? mesB = mesB+12 : mesB = mesB
    mesC = mesC < 1 ? mesC = mesC+12 : mesC = mesC
    mesD = mesD < 1 ? mesD = mesD+12 : mesD = mesD
    mesE = mesE < 1 ? mesE = mesE+12 : mesE = mesE
    mesF = mesF < 1 ? mesF = mesF+12 : mesF = mesF
    var anoB = mesB < 1 ? ano - 1 : ano
    var anoC = mesC < 1 ? ano - 1 : ano
    var anoD = mesD < 1 ? ano - 1 : ano
    var anoE = mesE < 1 ? ano - 1 : ano
    var anoF = mesF < 1 ? ano - 1 : ano
    
    var ultimos6meses=[mesF,mesE,mesD,mesC,mesB,mesA]

    //console.log(ultimos6meses)

    var dataStr = '24/11/2022'

    var dataNbr = parseInt((dataStr).substring(6,10))

    //DETERMINAR VALORES
    const [pedidos, setPedidos] = useState([]);
    const [dadosFin, setDadosFin] = useState({})
    const [bestsellers , setBestsellers] = useState ([])
    const [despesas,setDespesas] = useState([])

    useEffect(()=>{
        db.collection('pedidos').where('statusPedido','==','entregue').onSnapshot(function(snapshot){
            setPedidos(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        db.collection('despesas').orderBy('timestamp').onSnapshot(function(snapshot){
            setDespesas(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])
    //const pedido = pedidos.filter(pedidos => pedidos.data.statusPedido === 'entregue')
    //const sumall = kart.map(({ data }) => data.valor).reduce((prev, curr) => prev + curr, 0);
    
    const pedidosA = pedidos.filter(pedidos => pedidos.data.mes === mesA)
    const qtdA = pedidosA.length*30
    const sumallA = qtdA > 0
    ? pedidosA.map(({ data })=> data.total).reduce((prev, curr)=> prev + curr, 0)
    : 0;

    const pedidosB = pedidos.filter(pedidos => pedidos.data.mes === mesB && pedidos.data.ano == anoB)
    const qtdB = pedidosB.length*30
    const sumallB = qtdB > 0
    ? pedidosB.map(({ data })=> data.total).reduce((prev, curr)=> prev + curr, 0)
    : 0;

    const pedidosC = pedidos.filter(pedidos => pedidos.data.mes === mesC && pedidos.data.ano == anoC)
    const qtdC = pedidosC.length*30
    const sumallC = qtdC > 0
    ? pedidosC.map(({ data })=> data.total).reduce((prev, curr)=> prev + curr, 0)
    : 0;

    const pedidosD = pedidos.filter(pedidos => pedidos.data.mes === mesD && pedidos.data.ano == anoD)
    const qtdD = pedidosD.length*30
    const sumallD = qtdD > 0
    ? pedidosD.map(({ data })=> data.total).reduce((prev, curr)=> prev + curr, 0)
    : 0;

    const pedidosE = pedidos.filter(pedidos => pedidos.data.mes === mesE && pedidos.data.ano == anoE)
    const qtdE = pedidosE.length*30
    const sumallE = qtdE > 0
    ? pedidosE.map(({ data })=> data.total).reduce((prev, curr)=> prev + curr, 0)
    : 0;

    const pedidosF = pedidos.filter(pedidos => pedidos.data.mes === mesF && pedidos.data.ano == anoF)
    const qtdF = pedidosF.length*30
    const sumallF = qtdF > 0
    ? pedidosF.map(({ data })=> data.total).reduce((prev, curr)=> prev + curr, 0)
    : 0;

    const qtd = [qtdF,qtdE,qtdD,qtdC,qtdB,qtdA]
    const sumall = [sumallF,sumallE,sumallD,sumallC,sumallB,sumallA]
    
    useEffect(()=>{
        setDadosFin({
            quantidade: qtd,
            somaFin: sumall,
        })
        if(pedidos.length > 0){
          const produtos = pedidos.map((val)=> {
            return val.data.produtos
          })
          if(produtos.length > 0){
            var todosProdutos = produtos.flat(1)
            if(todosProdutos.length > 0){
              
              let counts = {}
              todosProdutos.forEach((count)=>{
                counts[count] = (counts[count] || 0) + 1
              })
              const maxVal = Math.max(...Object.values(counts))
              const num = Object.keys(counts).find((key) => counts[key] === maxVal)
              const arr2 = todosProdutos.filter(elem => elem !== num)
              arr2.forEach((count)=>{
                counts[count] = (counts[count] || 0) + 1
              })
              const maxVal2 = Math.max(...Object.values(counts))
              const num2 = Object.keys(counts).find((key) => counts[key] === maxVal2)
              const arr3 = arr2.filter(elem => elem !== num2)
              arr3.forEach((count)=>{
                counts[count] = (counts[count] || 0) + 1
              })
              const maxVal3 = Math.max(...Object.values(counts))
              const num3 = Object.keys(counts).find((key) => counts[key] === maxVal3)
              const arr4 = arr3.filter(elem => elem !== num3)
              arr4.forEach((count)=>{
                counts[count] = (counts[count] || 0) + 1
              })
              const maxVal4 = Math.max(...Object.values(counts))
              const num4 = Object.keys(counts).find((key) => counts[key] === maxVal4)
              const arr5 = arr4.filter(elem => elem !== num4)
              arr5.forEach((count)=>{
                counts[count] = (counts[count] || 0) + 1
              })
              const maxVal5 = Math.max(...Object.values(counts))
              const num5 = Object.keys(counts).find((key) => counts[key] === maxVal5)
              
              setBestsellers([num, maxVal, num2, (maxVal2/2), num3, (maxVal3/3), num4, (maxVal4/4), num5, (maxVal5/5)])
            }
          }
        }
        
    },[pedidos])

    //CALCULAR DESPESAS
    const calcDesp = (par , par2)=>{
      let i = (despesas.filter(despesas => despesas.data.mes == par && despesas.data.ano == par2))
      .map((val)=>{
        return(val.data.valor)
      })
      let somaI = parseFloat(i.reduce((prev, curr)=> prev + curr, 0)).toFixed(2)
      return(somaI)
    }
    const despesasMesA = calcDesp(mesA, ano)
    const despesasMesB = calcDesp(mesB, anoB)
    const despesasMesC = calcDesp(mesC, anoC)
    const despesasMesD = calcDesp(mesD, anoD)
    const despesasMesE = calcDesp(mesE, anoE)
    const despesasMesF = calcDesp(mesF, anoF)
    const despesasMeses = [despesasMesF,despesasMesE,despesasMesD,despesasMesC,despesasMesB,despesasMesA]
    
    return(
        <div className="container_comum">
            <TitleComum name='Controle Comercial' icon='bi bi-graph-up-arrow' />
            <div className="content_comum">
                <div className="row_comum">
                    <div className='container_comercial'>
                        <div className='card_comercial01'>
                            <h2>Últimos 6 meses</h2>
                            <div className='chart_container'><ComercialChart01 labels={labels} qtd={qtd} sumall={sumall} despesas={despesasMeses} /></div>
                        </div>
                        <div className='mini_container_comercial'>
                          <div className='mini_card_comercial'>
                            <h5>Pedidos Atendidos</h5>
                            <h2>{pedidosA.length}</h2>
                            <h4>{mesAtual}</h4>
                          </div>
                          <div className='mini_card_comercial'>
                            <h5>Pedidos Atendidos</h5>
                            <h2>{pedidosB.length}</h2>
                            <h4>Mês anterior</h4>
                          </div>
                          <div className='mini_panel_comercial'>
                              <div className='mini_panel_info'>
                                <h5>Lucro Bruto</h5>
                                <h3>R$ {(sumallA).toFixed(2)}</h3>
                              </div>
                              <div className='mini_panel_info'>
                                <h5 style={{color:'#bf2120'}}>Despesas do mês</h5>
                                <h3 style={{color:'#bf2120'}}>R$ {despesasMesA}</h3>
                              </div>
                              <div className='mini_panel_info'>
                                <h5 style={{color:'#4c7ccf'}}>Lucro Líquido</h5>
                                <h3 style={{color:'#4c7ccf'}}>R$ {(sumallA - despesasMesA).toFixed(2)}</h3>
                              </div>
                              <div className='mini_panel_info'>
                              <Link to="/despesas" meses={ultimos6meses}><button>Lançar despesas</button></Link>
                              </div>
                          </div>
                        </div>
                        <div className='card_comercial01'>
                            <h2>Produtos mais pedidos</h2>
                           <div className='chart_container'><ComercialChart02 labels={bestsellers} qtd={qtd} sumall={sumall} /></div>
                        </div>
                    </div>
                </div>
            </div> 
        </div> 
        )
}
export default Comercial