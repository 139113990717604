import TitleComum from './componentesCadastro/TitleComum'
import firebase from "firebase";
import { useState, useEffect } from 'react';

const Estoque =()=>{

    const db = firebase.firestore()
    const [estoque, setEstoque] = useState([]);

    const cadastrarEstoque = (e)=>{
		e.preventDefault();
        let item = document.getElementById('item').value;

	    db.collection('estoque').add({
            item: item,
            estado: true,
            disponibilidade: true,
          })

        document.getElementById('item').value = '';
	}
    useEffect(()=>{
        db.collection('estoque').onSnapshot(function(snapshot){
            setEstoque(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

    const setDisponibilidade =(e, par, par2)=>{
        e.preventDefault()
        let retorno = par == true ? false : true;

        db.collection('estoque').doc(par2).update({
            disponibilidade: retorno,
        })
    }
    const setEstado =(e, par, par2)=>{
        e.preventDefault()
        let retorno = par == true ? false : true;

        db.collection('estoque').doc(par2).update({
            estado: retorno,
        })
    }
    const deleteEstoque =(par)=>{
       db.collection('estoque').doc(par).delete()
    }
    const estado1 = estoque.filter(estoque => estoque.data.estado === true)
    const estado2 = estoque.filter(estoque => estoque.data.estado === false)

    return(
        <div className="container_comum">
            <TitleComum name='Controle de Estoque' icon='bi bi-boxes' />
            <div className="content_comum">
                <div className="row_comum">
                    <div className="estoque_cadastro">
                        <form onSubmit={cadastrarEstoque}>
                        <input type='text' placeholder="Digite aqui o ítem..." id='item' />
                        <button type='submit'>Incluir</button>
                        </form>          
                    </div>
                    <div className='table_container'>
                    <div className='table_estoque'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{width:'80%'}}>ítem</th>
                                <th style={{textAlign:'center'}}>Disp.</th>
                                <th style={{textAlign:'center'}}>Del.</th>
                            </tr>
                        </thead>
                        <tbody style={{lineHeight:'30px'}}>
                            {estado1.map((val)=>{
                                return(
                                <>
                                {val.data.estado == true
                                ?(
                                <tr key={val.id} style={{cursor:'pointer'}}>
                                    <td onClick={(e)=>setEstado(e, val.data.estado, val.id)}>{val.data.item}</td>
                                    <td style={{textAlign:'center'}} onClick={(e)=>setDisponibilidade(e, val.data.disponibilidade, val.id)}>
                                        {val.data.disponibilidade === true
                                        ?(<i className="bi bi-check-circle-fill" style={{color:'#21bc32'}}></i>)
                                        :(<i className="bi bi-x-octagon-fill" style={{color:'#bf2120'}}></i>)}
                                    </td>
                                    <td onClick={(e)=>deleteEstoque(val.id)}>
                                    <i className="bi bi-trash"></i>
                                    </td>
                                </tr>
                                )
                                :(
                                <tr key={val.id} style={{cursor:'pointer', backgroundColor:'#ccc', textDecoration:'line-through'}}>
                                    <td onClick={(e)=>setEstado(e, val.data.estado, val.id)}>{val.data.item}</td>
                                    <td style={{textAlign:'center'}}>
                                        {val.data.disponibilidade === true
                                        ?(<i className="bi bi-check-circle-fill" style={{color:'#666'}}></i>)
                                        :(<i className="bi bi-x-octagon-fill" style={{color:'#666'}}></i>)}
                                    </td>
                                    <td>
                                    <i className="bi bi-trash"></i>
                                    </td>
                                </tr>
                                )}
                                </>
                                )
                            })}
                            {estado2.map((val)=>{
                                return(
                                <>
                                {val.data.estado == true
                                ?(
                                <tr key={val.id} style={{cursor:'pointer'}}>
                                    <td onClick={(e)=>setEstado(e, val.data.estado, val.id)}>{val.data.item}</td>
                                    <td style={{textAlign:'center'}} onClick={(e)=>setDisponibilidade(e, val.data.disponibilidade, val.id)}>
                                        {val.data.disponibilidade === true
                                        ?(<i className="bi bi-check-circle-fill" style={{color:'#21bc32'}}></i>)
                                        :(<i className="bi bi-x-octagon-fill" style={{color:'#bf2120'}}></i>)}
                                    </td>
                                    <td onClick={(e)=>deleteEstoque(val.id)}>
                                    <i className="bi bi-trash"></i>
                                    </td>
                                </tr>
                                )
                                :(
                                <tr key={val.id} style={{cursor:'pointer', backgroundColor:'#ccc', textDecoration:'line-through'}}>
                                    <td onClick={(e)=>setEstado(e, val.data.estado, val.id)}>{val.data.item}</td>
                                    <td style={{textAlign:'center'}}>
                                        {val.data.disponibilidade === true
                                        ?(<i className="bi bi-check-circle-fill" style={{color:'#666'}}></i>)
                                        :(<i className="bi bi-x-octagon-fill" style={{color:'#666'}}></i>)}
                                    </td>
                                    <td>
                                    <i className="bi bi-trash"></i>
                                    </td>
                                </tr>
                                )}
                                </>
                                )
                            })}
                            
                        </tbody>
                    </table>
                    </div>
                    
                    </div>
                </div>
            </div> 
        </div>   
        )
}
export default Estoque