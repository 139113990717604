const HomeModalRecusa = ({ nPedido , idPedido, nao, sim})=>{

    

    return (
        <>
        <p>Tem certeza que deseja cancelar o pedido n°{nPedido}?</p>
            <div className='container_button'>
                <button onClick={(e)=>sim(e,idPedido)}>Sim</button>
                <button onClick={()=>nao(idPedido)}>Não</button>
            </div>
        <label htmlFor='motivo'>Motivo</label>
        <input type='text' name='motivo' id='motivo' style={{width:'100%'}} required/>
        </>
    )
}

export default HomeModalRecusa