import { useState, useEffect } from 'react';
import LinhaListaClientes from './LinhaListaClientes';

const ListaClientes = ({db})=>{
    const [usuarios, setUsuarios] = useState([]);

    useEffect(()=>{

        db.collection('users').onSnapshot(function(snapshot){
            setUsuarios(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

    const edit = (e , par)=>{
        e.preventDefault();
        alert(par)
    }

    const openAside = ()=>{
        let asideButton = document.getElementById("asidebutton");
        let element = document.getElementById("aside");
        let nav = document.getElementById("nav");
        
        asideButton.classList.toggle('is-active');
        
        element.classList.toggle('aside_aberto');
		element.classList.toggle('aside_fechado');
			
		nav.classList.toggle('nav_oculto');
		nav.classList.toggle('nav_visivel');
    }
          
    return(
            <div className='cliente_list'>
            <div className='modal_edit'></div>
                <h3>Clientes</h3>
                <div className='table_container' style={{maxHeight:'1500px'}}>
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Endereço</th>
                            <th>Telefone</th>
                            <th>Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usuarios.map((val)=>{
                            return(
                                <LinhaListaClientes key={val.id} id={val.id} email={val.email} db={db}/>
                            )
                        })}
                    </tbody>
                </table>
                </div>
            </div>
    )
}

export default ListaClientes