import Headerlogo from './../assets/img/logo_horizontal_dark.png'
import React from 'react';
import Estado from './components_layout/Estado';
import Alerta from './components_layout/Alerta';

const Header = ()=>{
 
    return(
            <header>
                <img src={Headerlogo} alt=""/>
                <div className='adm_container'>
		            <Estado />
                    <Alerta />
                </div>
            </header>
        )
}
export default Header