import { useEffect, useState } from 'react';
import firebase from "firebase";

const Estado = ()=>{
    const db = firebase.firestore()
    const [status, setStatus] = useState([])

    useEffect(()=>{
        db.collection('openclose').onSnapshot(function(snapshot){
            setStatus(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
            }))
        })
        },[])
        
    if(status.length > 0){
        var statusNow = status[0].data.status
    }else{
        statusNow = true
    }

    return(
        <>
        {statusNow === false
        ?(<div className='loja_fechada'>Fechado</div>)
        :(<div className='loja_aberta'>Aberto</div>)}
        </>
        )
}
export default Estado