import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

const LinhaListaClientes = ({db , id, email})=>{

    const [usuario, setUsuario] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{

        db.collection('users').doc(id).collection('cadastro').onSnapshot(function(snapshot){
            setUsuario(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

    const GoToClient = (par)=>{
        navigate('/clientes/'+par);
    }

    return (
        <>
        {usuario.map((val)=>{
            return(
                <tr key={val.id} onClick={()=>GoToClient(id)}>
                    <td>{val.data.name}</td>
                    <td>{val.data.email}</td>
                    <td>{val.data.street}, {val.data.number} - {val.data.complement}</td>
                    <td>{val.data.phone}</td>
                    <td>{val.data.typeOfCad}</td>
                </tr>
            )
        })}
        </>
        
    )
}

export default LinhaListaClientes