import { useEffect, useState } from 'react';
import firebase from "firebase";

const Alerta = ()=>{
    const db = firebase.firestore()
    const [pedidos, setPedidos] = useState([])

    useEffect(()=>{
        db.collection('pedidos').onSnapshot(function(snapshot){
            setPedidos(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
            }))
        })
        },[])

        const pedidosPendentes = pedidos.filter(pedidos => pedidos.data.statusPedido !== 'pedido cancelado' && pedidos.data.statusPedido !== 'entregue')

    return(
            <div className="alerta"><i className="bi bi-bell-fill"></i>
            {pedidosPendentes.length > 0
            ?(<span>{pedidosPendentes.length}</span>)
            :<></>}
            </div>
        )
}
export default Alerta