import firebase from "firebase";
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import TitleComum from './componentesCadastro/TitleComum'
import UsuarioModal from './UsuarioModal';

const Usuario = ()=>{        
    const db = firebase.firestore()
    const {id} = useParams();
    const [usuario, setUsuario] = useState([]);

    useEffect(()=>{

        db.collection('users').doc(id).collection('cadastro').onSnapshot(function(snapshot){
            setUsuario(snapshot.docs.map(function(document){
                return{id:document.id,data:document.data()}
              }))
            })
        },[])

        if(usuario.length > 0){
           var userName = usuario[0].data.name
        }else{
            userName = 'Indeterminado'
        }

        const atualizar = (e)=>{
            e.preventDefault();
                let name = document.getElementById('name').value;
                let zipcode = document.getElementById('zipcode').value;
                let street = document.getElementById('street').value;
                let number = document.getElementById('number').value;
                let complement = document.getElementById('complement').value;
                let phone = document.getElementById('phone').value;
                let email = document.getElementById('email').value;
                let idDoc = usuario[0].id;
          
                db.collection('users').doc(id).collection('cadastro').doc(idDoc).update({
                  name: name,
                  email: email,
                  zipcode: zipcode,
                  street: street,
                  number: number,
                  complement: complement,
                  phone: phone,
                  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                })
                alert('Usuário atualizado!')
                
        }

        const mudarTipo = (par1,par2)=>{
            document.getElementById('muda_usuario').style.display = 'block';
        }
    
        const nao = ()=>{
            document.getElementById('muda_usuario').style.display = 'none';
        }

        //Tipo de usuário <span>{val.data.typeOfCad}</span>

    return(
        <div>
			<div className="container_comum">
            <TitleComum name={'Perfil de: '+userName} icon='bi bi-people' />
                <div className="content_comum">
                    <div className="row_comum">
                    {usuario.map((val)=>{
                        return(
                            <div className='usuario_container' key={val.id}>
                                        <div className='muda_usuario' id='muda_usuario'>
                                            <UsuarioModal
                                                db={db} nao={nao}
                                                idUsuario={id}
                                                tipoUsuario={val.data.typeOfCad}
                                                nomeUsuario={val.data.name}
                                                idDoc={val.id}
                                            />
                                        </div>
                                <div className='topo_usuario'><h1>{val.data.name}</h1>
                                    <div className='tipo_usuario' onClick={()=>mudarTipo(val.id,val.data.typeOfCad)}>
                                        {val.data.typeOfCad === 'cliente'
                                        ?(<h4>Tornar administrador</h4>)
                                        :(<h4>Tornar cliente</h4>)
                                        }
                                    </div>
                                </div>
                                
                                <form onSubmit={atualizar}>
                                    <label htmlFor='name'>Nome</label>
                                    <input type="text" name='name' id='name' defaultValue={val.data.name}/>

                                    <label htmlFor='email' style={{width:'calc(50% - 4px)'}}>E-mail</label><label htmlFor='phonenumber'  style={{width:'calc(50% - 4px)',marginLeft:'8px'}}>Telefone</label>
                                    <input type='email' name='email' id='email' placeholder='email' value={val.data.email} readOnly/>
                                    <input type='phonenumber' name='phone' id='phone' defaultValue={val.data.phone} />

                                    <label htmlFor='street'>Endereço</label>
                                    <input type='text' name='street' id='street' defaultValue={val.data.street} />

                                    <label htmlFor='number' style={{width:'calc(20%)',marginRight:'8px'}}>N°</label>
                                    <label htmlFor='complement' style={{width:'calc(30% - 8px)',marginRight:'8px'}}>Complemento</label>
                                    <label htmlFor='neighborhood' style={{width:'calc(30% - 8px)',marginRight:'8px'}}>Bairro</label>
                                    <label htmlFor='zipcode' style={{width:'calc(20% - 8px)'}}>CEP</label>
                                    <input type='number' name='number' id='number' defaultValue={val.data.number} style={{marginRight:'8px'}} />
                                    <input type='text' name='complement' id='complement' defaultValue={val.data.complement}  style={{width:'calc(30% - 8px)',marginRight:'8px'}} />
                                    <input type='text' name='complement' id='complement' defaultValue={val.data.neighborhood}  style={{width:'calc(30% - 8px)',marginRight:'8px'}} />
                                    <input type='text' name='zipcode' id='zipcode' defaultValue={val.data.zipcode} style={{width:'calc(20% - 8px)'}}/>

                                    <input type="submit" name='acao' value='Atualizar' />
                                </form>
                            </div>
                            )
                        })}
                    </div>
                </div>
			</div>
        </div>
        )
}

export default Usuario